import React from 'react';
import {BleepsProvider} from '@arwes/sounds';
import {audioSettings, bleepsSettings, generalAnimator, playersSettings} from '../config';
import {Animator, AnimatorGeneralProvider} from '@arwes/animation';
import {Link} from 'react-router-dom';
import {Button, FrameCorners, FrameHexagon, List, Text} from '@arwes/core';
import './HomePage.scss';

export class HomePage extends React.Component {
  public render() {
    return <div className="home-page">
      <BleepsProvider
        audioSettings={audioSettings}
        playersSettings={playersSettings}
        bleepsSettings={bleepsSettings}
      >
        <AnimatorGeneralProvider animator={generalAnimator}>
          <Animator animator={{manager: 'stagger', activate: true}}>
            <FrameCorners>
              <Text as="h1">
                Aedership / Schiffsanalysetools
              </Text>
              <Text as="p">
                Die Shiffsanalysetools Aedership ermöglichen eine rasche und unkomplizierte Analyse zum aktuellen
                Zustand diverser Schiffstypen. Die meist verwendeten Kategorien sind dabei unterstützt. Solange sie ein
                Analyseschnittstelle AES-AI bieten.
              </Text>
              <Text as="p">
                Letzte Änderungen
              </Text>
              <Text as="strong">
                2021.12.29 - Verbesserte Konfigurationsseite
              </Text>
              <List>
                <li>Module sind nun ausklappar</li>
                <li>Alle Module können mit einem klick geschlossen oder geöffnet werden</li>
                <li>Problem gelöst mit Horizontalem scrolling auf Mobile</li>
              </List>
              <Text as="strong">
                2021.12.28 - Wahrscheinlichkeiten anpassen
              </Text>
              <List>
                <li>Neuer Settings-Button im oberen, rechten Rand</li>
                <li>Wahrscheinlichkeiten zur Erkennung von Warnungen und Kritischen Meldung kann nun angepasst werden</li>
              </List>
              <Text as="strong">
                2021.12.05 - Nur aktive Schiffsmodule analysieren
              </Text>
              <List>
                <li>Schiffsmodule bleiben nun aktiv wenn man die Seiten wechselt</li>
                <li>Im Analyse Screen werden nur die Schiffsmodule aufgelistet, welche auch ausgewählt wurden</li>
                <li>Neu laden von Unterseite funkioniert jetzt</li>
              </List>
              <Text as="strong">
                2021.12.04 - Auswahl Schiffsmodule und Analyse Animationen
              </Text>
              <List>
                <li>Shiffsmodule können ausgewählt werden inder Übersicht (noch keine Anpassung an den effektiven Ausgaben</li>
                <li>Analyse-Screen animiert nun "sequentieller"</li>
              </List>
              <Text as="strong">
                2021.11.28 - Erste Version Analyse-Screen
              </Text>
              <List>
                <li>Knopf "Analyse Starten" funktioniert jetzt</li>
                <li>Analyse-Screen listet alle Module mit allen Meldungen auf und färbt sie ein</li>
              </List>
              <Link to="/shiplist">
                <Button FrameComponent={FrameHexagon}>
                  <Text>Analysetool starten</Text>
                </Button>
              </Link>
            </FrameCorners>
          </Animator>
        </AnimatorGeneralProvider>
      </BleepsProvider>
    </div>;
  }
}
