export const ROOT_FONT_FAMILY = '"Titillium Web", sans-serif';
export const SOUND_OBJECT_URL = '/assets/bleeps/object.mp3';
export const SOUND_TYPE_URL = '/assets/bleeps/type.mp3';
export const audioSettings = {common: {volume: 0.25}};
export const playersSettings = {
  object: {src: [SOUND_OBJECT_URL]},
  type: {src: [SOUND_TYPE_URL], loop: true}
};
export const bleepsSettings = {
  object: {player: 'object'},
  type: {player: 'type'}
};
export const generalAnimator = {duration: {enter: 200, exit: 200}};

export interface EMPTY_PROPS {}
