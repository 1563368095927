import React from 'react';
import './App.scss';
import {ArwesThemeProvider, Button, FrameUnderline, StylesBaseline} from '@arwes/core';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';

import ShipListPage from '../pages/ShipListPage';
import {ROOT_FONT_FAMILY} from '../config';
import {HomePage} from '../pages/HomePage';
import AnalyzeScreenPage from '../pages/AnalyzeScreenPage';
import ShipPage from '../pages/ShipPage';
import ConfigPage from '../pages/ConfigPage';

class App extends React.Component {
  private renderConfigButton() {
    return <Link className="config" to="/config">
      <Button FrameComponent={FrameUnderline}>
        <span className="material-icons">settings</span>
      </Button>
    </Link>;
  }


  public render() {
    return (
      <BrowserRouter>
        <ArwesThemeProvider>
          <StylesBaseline styles={{body: {fontFamily: ROOT_FONT_FAMILY}}}/>
          <div className="app-wrapper">
            <Switch>
              <Route path="/" exact={true}>
                <HomePage/>
              </Route>
              <Route path="/config" exact={true}>
                <ConfigPage/>
              </Route>
              <Route path="/shiplist" exact={true}>
                {this.renderConfigButton()}
                <ShipListPage/>
              </Route>
              <Route path="/ship/:id" exact={true}>
                {this.renderConfigButton()}
                <ShipPage/>
              </Route>
              <Route path="/ship/:id/analyze" exact={true}>
                {this.renderConfigButton()}
                <AnalyzeScreenPage/>
              </Route>
            </Switch>
          </div>
        </ArwesThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
