export interface Ship {
  id: string;
  name: string;
  description: string;
  image: string;
}

class ShipService {
  public getShips(): Ship[] {
    return  [
      {
        id: "aegis",
        name: "Aegis",
        image: "/assets/images/ships/Aegis (Search and Rescue Ship).jpg",
        description: "Search and Rescue Ship"
      },
      {
        id: "aquilla",
        name: "Aquilla",
        image: "/assets/images/ships/Aquilla (Bewaffneter Händler).jpg",
        description: "Bewaffneter Händler"
      },
      {
        id: "archimedes",
        name: "Archimedes",
        image: "/assets/images/ships/Archimedes (Racer).jpg",
        description: "Racer"
      },
      {
        id: "aurora",
        name: "Aurora",
        image: "/assets/images/ships/Aurora (Small Frighter).jpg",
        description: "Small Frighter"
      },
      {
        id: "carack",
        name: "Carack",
        image: "/assets/images/ships/Carack (Grosser Exporer).jpg",
        description: "Grosser Exporer"
      },
      {
        id: "caterpillar",
        name: "Caterpillar",
        image: "/assets/images/ships/Caterpillar (Heavy Frighter).jpg",
        description: "Heavy Frighter"
      },
      {
        id: "freelancer-trader",
        name: "Freelancer Händler",
        image: "/assets/images/ships/Freelancer (Kleiner bewaffner Händler).jpg",
        description: "Kleiner bewaffner Händler"
      },
      {
        id: "hammerhead",
        name: "Hammerhead",
        image: "/assets/images/ships/Hammerhead (Kriegsfregatte).jpg",
        description: "Kriegsfregatte"
      },
      {
        id: "perseus",
        name: "Perseus",
        image: "/assets/images/ships/Perseus (Kriegscorvette).jpg",
        description: "Kriegscorvette"
      },
      {
        id: "raft",
        name: "Raft",
        image: "/assets/images/ships/Raft (Medium Frighter).jpg",
        description: "Medium Frighter"
      },
      {
        id: "starliner",
        name: "Starliner",
        image: "/assets/images/ships/Starliner (Personal Transport).jpg",
        description: "Personal Transport"
      },
      {
        id: "vulture-ship",
        name: "Vulture Ship",
        image: "/assets/images/ships/Vulture (Small Minig Ship).jpg",
        description: "Small Minig Ship"
      }
    ];
  }

  public getShipById(id: string): Ship | undefined {
    return this.getShips().find(ship => ship.id === id);
  }
}

const shipService = new ShipService();

export default shipService;
