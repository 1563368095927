import React, {useEffect, useState} from 'react';
import './ConfigPage.scss';
import {Animator, AnimatorGeneralProvider} from '@arwes/animation';
import {audioSettings, bleepsSettings, generalAnimator, playersSettings} from '../config';
import {BleepsProvider} from '@arwes/sounds';
import {Button, FrameUnderline, List, Text} from '@arwes/core';
import shipModuleService, {
  getProbability,
  MessageType,
  OptionalMessageTypes,
  ShipModule
} from '../services/ship-module-service';
import {PROBABILITY_DEFAULT_CRITICAL, PROBABILITY_DEFAULT_WARNING} from '../services/ship-modules.data';

type Props = {
  messageType: MessageType;
  message: string;
  probability: number;
  onProbabilityChange: (probability: number) => void;
};

export const MessageProbabilityInput = (props: Props) => {
  const [probability, updateRange] = useState(props.probability);

  useEffect(() => {
    updateRange(props.probability);
  }, [props.probability])

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event?.target) {
      return;
    }
    const probability = parseFloat((event.target as HTMLButtonElement).value);
    updateRange(probability);
    props.onProbabilityChange(probability);
  }

  return <div className={props.messageType}>
    <Text as="h3">{props.messageType === MessageType.WARNING ? "Warnung" : "Kritisch"}</Text>
    <span className="probability-text">Wahrsch.: {Math.floor(probability * 100)}%</span>
    <Text className={props.messageType}>{props.message}</Text><br/>
    <div className="probability-wrapper">
      <label className="probability-field-wrapper">
        <input type="range" min="0" max="1" step="0.01" value={probability} onChange={handleOnChange}/>
      </label>
    </div>
  </div>;
};

class ConfigPage extends React.Component<{}, { key: number; shipModules: ShipModule[] }> {

  public constructor(props: {}) {
    super(props);
    this.state = {key: 0, shipModules: shipModuleService.getShipModules()};
  }

  private onProbabilityChange(shipModule: ShipModule, messageType: OptionalMessageTypes, probability: number) {
    shipModuleService.changeProbability(shipModule.id, messageType, probability);
  }

  private renderShipModuleMessages(shipModule: ShipModule) {
    const messageSet = shipModule.messages;
    const probabilities = shipModule.messageProbabilities;
    const warningProbability = getProbability(MessageType.WARNING, probabilities);
    const criticalProbability = getProbability(MessageType.CRITICAL, probabilities);

    if (!messageSet?.critical && !messageSet?.warning) {
      return shipModule.subModules ? this.renderShipModules(shipModule.subModules) : '';
    }
    return <li className="flex-row">
      {messageSet.warning ?
        <MessageProbabilityInput probability={warningProbability} message={messageSet.warning} messageType={MessageType.WARNING} onProbabilityChange={(probability) => this.onProbabilityChange(shipModule, MessageType.WARNING, probability)}/> : <></>}
      {messageSet.critical ?
        <MessageProbabilityInput probability={criticalProbability} message={messageSet.critical} messageType={MessageType.CRITICAL} onProbabilityChange={(probability) => this.onProbabilityChange(shipModule, MessageType.CRITICAL, probability)}/> : <></>}
      {shipModule.subModules ? this.renderShipModules(shipModule.subModules) : ''}
    </li>
  }


  private renderShipModule(shipModule: ShipModule) {
    return <li key={shipModule.id} className="closed">
      <Text as="h3">
        <span onClick={this.onListClick}>
                  <span className="material-icons visible-on-closed">add</span>
        <span className="material-icons visible-on-open">remove</span>
        <span>{shipModule.name}</span>
        </span>
      </Text>
      <List>
        {this.renderShipModuleMessages(shipModule)}
      </List>
    </li>;
  }

  private renderShipModules(shipModules: ShipModule[]) {
    return shipModules.map((shipModule, i) => this.renderShipModule(shipModule)
    );
  }

  public render() {
    return (
      <div className="config-page">
        <BleepsProvider
          audioSettings={audioSettings}
          playersSettings={playersSettings}
          bleepsSettings={bleepsSettings}
        >
          <AnimatorGeneralProvider animator={generalAnimator}>
            <Animator animator={{manager: 'stagger', activate: true}}>
              <Button className="back-button" FrameComponent={FrameUnderline} onClick={() => window.history.back()}>
                <Text>← Zurück</Text>
              </Button>
              <div className="quick-settings">
                <Button onClick={() => this.setAllProbabilities(0, 0)}>
                  <Text>Alle Wahrsch. auf 0%</Text>
                </Button>
                <Button onClick={() => this.setAllProbabilities(PROBABILITY_DEFAULT_WARNING, PROBABILITY_DEFAULT_CRITICAL)}>
                  <Text>Alle Wahrsch. auf W: {PROBABILITY_DEFAULT_WARNING * 100}%,
                    K: {PROBABILITY_DEFAULT_CRITICAL * 100}%</Text>
                </Button>
                <Button onClick={() => this.setAllProbabilities(1, 1)}>
                  <Text>Alle Wahrsch. auf 100%</Text>
                </Button>
              </div>
              <Text as="h2">Wahrscheinlichkeiten</Text>
              <div className="module-list-wrapper">
                <Text as="h2">
                  Module
                </Text>
                <div className="open-close-buttons">
                  <Button onClick={() => this.openAll()}>
                    <Text>Alle Öffnen</Text>
                  </Button>
                  <Button onClick={() => this.closeAll()}>
                    <Text>Alle Schliessen</Text>
                  </Button>
                </div>
                <List className="shipModuleList">
                  {this.renderShipModules(this.state.shipModules)}
                </List>
              </div>
            </Animator>
          </AnimatorGeneralProvider>
        </BleepsProvider>
      </div>
    );
  }

  private setAllProbabilities(warningProbability: number, criticalProbability: number): void {
    shipModuleService.changeAllProbabilities(warningProbability, criticalProbability);

    this.setState({key: Math.random(), shipModules: shipModuleService.getShipModules()});
  }

  private onListClick(event: React.MouseEvent<HTMLElement>) {
    const liElement = (event.target as HTMLElement).closest('li');
    if (!liElement) {
      return;
    }
    const isClosed = liElement.classList.contains("closed");
    if (isClosed) {
      liElement.classList.remove("closed");
    } else {
      liElement.classList.add("closed");
    }

  }

  private openAll(): void {
    const allLIs = document.querySelectorAll(".shipModuleList li");
    allLIs.forEach(li => li.classList.remove("closed"));
  }

  private closeAll(): void {
    const allLIs = document.querySelectorAll(".shipModuleList li");
    allLIs.forEach(li => li.classList.add("closed"));
  }
}

export default ConfigPage;

