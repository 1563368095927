import React from 'react';
import './ShipListPage.scss';
import {Animator, AnimatorGeneralProvider} from '@arwes/animation';
import {Link} from 'react-router-dom';
import shipService, {Ship} from '../services/ship-service';
import {ShipCard} from '../components/ShipCard';
import {audioSettings, bleepsSettings, generalAnimator, playersSettings} from '../config';
import {BleepsProvider} from '@arwes/sounds';

class ShipListPage extends React.Component {

  public renderShips(ships: Ship[]) {
    return ships.map((ship, i) =>
      <li key={ship.id}>
        <Link to={`/ship/${ship.id}`}>
          <ShipCard title={ship.name} image={ship.image} text={ship.description}/>
        </Link>
      </li>
    );
  }

  public render() {

    const ships = shipService.getShips();

    return (
      <div className="shiplist-page">
        <BleepsProvider
          audioSettings={audioSettings}
          playersSettings={playersSettings}
          bleepsSettings={bleepsSettings}
        >
          <AnimatorGeneralProvider animator={generalAnimator}>
            <Animator animator={{manager: 'stagger', activate: true}}>
              <ul>
                {this.renderShips(ships)}
              </ul>
            </Animator>
          </AnimatorGeneralProvider>
        </BleepsProvider>
      </div>
    );
  }
}

export default ShipListPage;

