import './ShipPage.scss';
import React from 'react';
import shipService, {Ship} from '../services/ship-service';
import PropTypes from 'prop-types';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {Button, Figure, FrameCorners, FrameHexagon, FrameUnderline, List, Text} from '@arwes/core';
import shipModuleService, {ShipModule} from '../services/ship-module-service';
import {audioSettings, bleepsSettings, generalAnimator, playersSettings} from '../config';
import {BleepsProvider} from '@arwes/sounds';
import {Animator, AnimatorGeneralProvider} from '@arwes/animation';

interface RoutingParamsType {
  id: string;
}

interface ComponentParams extends RouteComponentProps<RoutingParamsType> {

}

interface State {
  ship?: Ship;
}

class ShipPage extends React.Component<ComponentParams, State> {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  public constructor(params: ComponentParams) {
    super(params);
  }

  public componentDidMount() {
    const id = this.props.match.params.id;
    const ship = shipService.getShipById(id);
    this.setState({ship});
  }

  public render() {
    const ship = this.state?.ship;
    if (!ship) {
      return <div>No ship loaded</div>;
    }
    return <div className="ship-overview-page">
      <BleepsProvider
        audioSettings={audioSettings}
        playersSettings={playersSettings}
        bleepsSettings={bleepsSettings}
      >
        <AnimatorGeneralProvider animator={generalAnimator}>
          <Animator animator={{manager: 'stagger', activate: true}}>
            <Link to="/shiplist" className="back-button">
              <Button className="back-button" FrameComponent={FrameUnderline}>
                <Text>← Zur Übersicht</Text>
              </Button>
            </Link>
            <Text as="h1">
              {ship.name}
            </Text>
            <Text as="p">
              {ship.description}
            </Text>
            <Figure
              src={ship.image}
              alt={"Image for " + ship.name}
            />
            <Link to={`/ship/${ship.id}/analyze`}>
              <Button FrameComponent={FrameHexagon} onClick={event => console.log(event)}>
                <Text>Analyse starten</Text>
              </Button>
            </Link>
            <div className="wrapper">
              <div className="module-list-wrapper">
                <Text as="h2">
                  Allgemeine Module
                </Text>
                <List className="shipModuleList">
                  {this.renderShipModules(shipModuleService.getShipModules().filter(module => module.basic), shipModuleService.getActiveShipModules())}
                </List>
              </div>
              <div className="module-list-wrapper">
                <Text as="h2">
                  Erweiterungsmodule
                </Text>
                <List className="shipModuleList">
                  {this.renderShipModules(shipModuleService.getShipModules().filter(module => !module.basic), shipModuleService.getActiveShipModules())}
                </List>
              </div>
            </div>
          </Animator>
        </AnimatorGeneralProvider>
      </BleepsProvider>
    </div>;
  }

  private toggleModule(shipModule: ShipModule) {
    if(shipModuleService.isActive(shipModule)) {
      shipModuleService.activateModule(shipModule);
    } else {
      shipModuleService.deactivateModule(shipModule);
    }
    this.setState(this.state)
  }

  private renderShipModules(shipModules: ShipModule[], activeModules: ShipModule[]) {
    return shipModules.map((shipModule, i) =>
      <li key={shipModule.id} onClick={() => this.toggleModule(shipModule)}>
        <FrameCorners>
          <span className="material-icons">{shipModuleService.isActive(shipModule) ? 'check_box' : 'check_box_outline_blank'}</span>
          <Text>{shipModule.name}</Text>
        </FrameCorners>
      </li>
    );
  }
}

export default withRouter<ComponentParams, any>(ShipPage);
