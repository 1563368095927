import React from 'react';
import {Button, Figure, FrameHexagon, Text} from '@arwes/core';

export interface ShipCardProps {
  title: string,
  image: string,
  text: string,
}

export class ShipCard extends React.Component<ShipCardProps> {

  public constructor(props: ShipCardProps) {
    super(props);
  }

  render() {
    return <>
      <Text as="h1">
        {this.props.title}
      </Text>
      <Figure
        src={this.props.image}
        alt={"Image for " + this.props.title}
      />
      <Text as="p">
        {this.props.text}
      </Text>
      <Button FrameComponent={FrameHexagon} onClick={event => console.log(event)}>
        <Text>Verbinde Analysetool</Text>
      </Button>
    </>;
  }
}
