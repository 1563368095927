import {MessageSet, ShipModule} from './ship-module-service';

export interface ShipModuleDataRow {
  id: string;
  parentId: string | null;
  name: string;
  basic?: boolean;
  messages: Partial<MessageSet>;
}

const ShipModuleDataDB: ShipModuleDataRow[] = [{
  id: '1',
  parentId: '0',
  name: 'Ionenantrieb',
  messages: {
    intro: '',
    info: '',
    ok: 'Ionenantrieb Funktionsbereit',
    critical: '',
    warning: ''
  }
}, {
  id: '2',
  parentId: '1',
  name: 'Induktionsspule',
  messages: {
    intro: '',
    info: '',
    ok: 'Induktionsspule vollständig geladen',
    critical: '',
    warning: ''
  }
}, {
  id: '3',
  parentId: '2',
  name: 'Magnetfeld',
  messages: {
    intro: 'Analysiere Magnetfeldspannung',
    info: '',
    ok: 'Magnetfeld Integer',
    critical: 'Magnetfeld unter 3 Tesla',
    warning: 'Magnetresonatz instabil'
  }
}, {
  id: '4',
  parentId: '1',
  name: 'Reaktor',
  messages: {
    intro: 'Analysiere Reaktorintegrität',
    info: 'Reaktor bei 4568°K',
    ok: 'Reaktorintegrität stabil',
    critical: '',
    warning: ''
  }
}, {
  id: '5',
  parentId: '4',
  name: 'Brennkammer',
  messages: {
    intro: 'Analysiere Kerntemperatur',
    info: 'Fusionseffizienz bei 92%',
    ok: 'Kerntemperatur stabil',
    critical: '',
    warning: ''
  }
}, {
  id: '6',
  parentId: '5',
  name: 'Schwingungsregulierer',
  messages: {
    intro: 'Verifiziere Schwingungsfrequenz und Resonanzkammer',
    info: 'Schwingung stabil mit: 44421.337mHz',
    ok: 'Schwingungsregulierer OK. Resonanz Stabil',
    critical: 'Arrhythmische Schwingungen entdeckt!',
    warning: 'Rekalibrierung des Resonanzgenerator notwendig'
  }
}, {
  id: '7',
  parentId: '5',
  name: 'Stabilisator',
  messages: {
    intro: '',
    info: 'Stabilisator bei 76%',
    ok: 'Stabilisator stabil',
    critical: 'Stabilisator spannungsabfall',
    warning: 'Stabilisator schwankend'
  }
}, {
  id: '8',
  parentId: '5',
  name: 'Reaktionsmasse',
  messages: {
    intro: 'Analysiere Mischverhältniss',
    info: 'Mischung bei 43%:57%',
    ok: 'Mischverhältnis im akzeptablen Bereich',
    critical: 'Deuteriumwert bei 43%',
    warning: 'Deuteriumwert schwankend'
  }
}, {
  id: '9',
  parentId: '4',
  name: 'Reaktorabschirmung',
  messages: {
    intro: '',
    info: 'Reaktorabschirmung bei 4.5845698 Tesla',
    ok: 'Reaktorabschirmung Stabil',
    critical: 'Reaktorabschirmungssicherung durchgebrannt',
    warning: 'Reaktionsabschirmung zeigt Risse auf'
  }
}, {
  id: '10',
  parentId: '1',
  name: 'Magnetdüse',
  messages: {
    intro: '',
    info: 'Magnetdüse Materialintegrität bei 89%',
    ok: 'Magnetdüse voll Einsatzbereit',
    critical: 'Magnetdüse zu 12% Leck geschlagen',
    warning: 'Magnetdüse hat 7% Leistungsabfall'
  }
}, {
  id: '11',
  parentId: '1',
  name: 'Brennstofftank',
  messages: {
    intro: '',
    info: 'Tankfüllung: 58%',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '12',
  parentId: '11',
  name: 'Tankülle',
  messages: {
    intro: '',
    info: 'Tankhülle bei 97.5% Integrität',
    ok: 'Hüllenstruktur intakt',
    critical: 'Tankhülle leckt mit 23 Kg/h',
    warning: 'Tankhüllenintegrität unter 99%'
  }
}, {
  id: '13',
  parentId: '11',
  name: 'Kühlung',
  messages: {
    intro: '',
    info: 'Temperator: 58°K',
    ok: '',
    critical: 'Kritische Temperatur von 120°K erreicht',
    warning: ''
  }
}, {
  id: '14',
  parentId: '11',
  name: 'Dosierer',
  messages: {
    intro: '',
    info: 'Bei Volllast 146 Kg/h Reaktionsmasseverbrauch',
    ok: 'Einspritzdüsen i.O',
    critical: 'Einspritzdüse bei 87% effizenz',
    warning: 'Kalibrierung notwendig'
  }
}, {
  id: '15',
  parentId: '0',
  name: 'Boardcomputer',
  messages: {
    intro: '',
    info: 'Online',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '16',
  parentId: '15',
  name: 'Netzwerk',
  messages: {
    intro: '',
    info: 'Online',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '17',
  parentId: '16',
  name: 'Interfaces',
  messages: {
    intro: '',
    info: 'Online',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '18',
  parentId: '17',
  name: 'Reaktor',
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '19',
  parentId: '17',
  name: 'MedBay',
  basic: false,
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '20',
  parentId: '19',
  name: 'Replikator',
  basic: false,
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '21',
  parentId: '19',
  name: 'Stasis-Kammer',
  basic: false,
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '22',
  parentId: '19',
  name: 'OP-Roboter',
  basic: false,
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '24',
  parentId: '0',
  name: 'Waffensysteme',
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '25',
  parentId: '24',
  name: 'Gauss',
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '26',
  parentId: '25',
  name: 'Magnetspulen',
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '27',
  parentId: '25',
  name: 'Projektile',
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '28',
  parentId: '25',
  name: 'Kühlung',
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '29',
  parentId: '0',
  name: 'Med-Bay',
  basic: false,
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '30',
  parentId: '29',
  name: 'Replikator',
  basic: false,
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '31',
  parentId: '29',
  name: 'Stasis-Kammer',
  basic: false,
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}, {
  id: '32',
  parentId: '29',
  name: 'OP-Roboter',
  basic: false,
  messages: {
    intro: '',
    info: '',
    ok: '',
    critical: '',
    warning: ''
  }
}];

const mapMessages = (shipModule: ShipModule, messageSet: Partial<MessageSet>): MessageSet => {
  const undefinedOnEmpty = (value?: string) => !!value ? value : undefined;

  const cleanedUpMessages: Partial<MessageSet> = {
    intro: undefinedOnEmpty(messageSet.intro),
    info: undefinedOnEmpty(messageSet.info),
    ok: undefinedOnEmpty(messageSet.ok),
    critical: undefinedOnEmpty(messageSet.critical),
    warning: undefinedOnEmpty(messageSet.warning)
  };

  const defaultMessages: Partial<MessageSet> = {
    intro: "Analysiere %NAME%".replaceAll("%NAME%", shipModule.name),
    ok: "%NAME% OK".replaceAll("%NAME%", shipModule.name)
  };

  return {
    ...cleanedUpMessages,
    ...defaultMessages
  } as MessageSet;
}

const mapShipModule = (shipDataRow: ShipModuleDataRow): ShipModule => {
  const shipModule: ShipModule = {
    id: shipDataRow.id,
    parentId: !!shipDataRow.parentId && shipDataRow.parentId !== "0" ? shipDataRow.parentId : null,
    basic: shipDataRow.basic === undefined ? true : shipDataRow.basic,
    name: shipDataRow.name
  };
  shipModule.messages = mapMessages(shipModule, shipDataRow.messages);
  return shipModule;
}

const makeShipModuleHierarchy = (shipDataRows: ShipModuleDataRow[]): ShipModule[] => {
  const map = new Map<string, ShipModule>();
  for (let row of shipDataRows) {
    const shipModule = mapShipModule(row);
    if (!map.has(shipModule.id)) {
      map.set(shipModule.id, shipModule);
    }
  }

  for (let shipModule of Array.from(map.values())) {
    if (!shipModule.parentId) {
      continue;
    }
    if (!map.has(shipModule.parentId)) {
      throw new Error(`Cant find shipModule parent With id "${shipModule.parentId}"`)
    }
    const parentModule = map.get(shipModule.parentId) as ShipModule;
    if(!parentModule.subModules) {
      parentModule.subModules = [];
    }
    parentModule?.subModules?.push(shipModule);
  }

  Array.from(map.values()).forEach(shipModule => {
    shipModule.messageProbabilities = {
      critical: PROBABILITY_DEFAULT_CRITICAL,
      warning: PROBABILITY_DEFAULT_WARNING
    }
  })

  return Array.from(map.values()).filter(shipModule => shipModule.parentId === null);
};

export const PROBABILITY_DEFAULT_CRITICAL = 0.15;
export const PROBABILITY_DEFAULT_WARNING = 0.35;

export const ShipModuleData: ShipModule[] = makeShipModuleHierarchy(ShipModuleDataDB)
