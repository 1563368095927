import {ShipModuleData} from './ship-modules.data';
import {cloneDeep} from 'lodash'

const isSameModule = (shipModuleA: ShipModule, shipModuleB: ShipModule): boolean => shipModuleA.id === shipModuleB.id;

export enum MessageType {
  INTRO = 'intro',
  INFO = 'info',
  OK = 'ok',
  CRITICAL = 'critical',
  WARNING = 'warning',
}

export type OptionalMessageTypes = MessageType.WARNING | MessageType.CRITICAL;


export interface MessageProbabilities {
  [MessageType.WARNING]?: number;
  [MessageType.CRITICAL]?: number;
}

export interface MessageSet {
  [MessageType.INTRO]: string;
  [MessageType.INFO]?: string;
  [MessageType.OK]?: string;
  [MessageType.CRITICAL]?: string;
  [MessageType.WARNING]?: string;
}

export interface ShipModule {
  id: string;
  parentId: string | null;
  name: string;
  basic: boolean;
  messages?: MessageSet;
  messageProbabilities?: MessageProbabilities
  subModules?: ShipModule[];
}

class ShipModuleService {
  private activeModules: ShipModule[] = [];

  public constructor(private shipModules: ShipModule[]) {
    this.activeModules = shipModules.filter(shipModule => shipModule.basic);
  }

  public getShipModules(): ShipModule[] {
    return this._getShipModules(true);
  }

  public getShipModuleById(id: string): ShipModule | undefined {
    return this._getShipModuleById(id);
  }

  public getActiveShipModules(): ShipModule[] {
    return cloneDeep(this.activeModules);
  }

  public setActiveModules(shipModules: ShipModule[]) {
    this.activeModules = shipModules;
  }

  public isActive(shipModule: ShipModule): boolean {
    return !!this.activeModules.find(activeModule => isSameModule(shipModule, activeModule));
  }

  public activateModule(shipModule: ShipModule) {
    if (this.isActive(shipModule)) {
      this.activeModules.splice(this.activeModules.findIndex(activeModule => isSameModule(shipModule, activeModule)), 1);
    }
  }

  public deactivateModule(shipModule: ShipModule) {
    if (!this.isActive(shipModule)) {
      this.activeModules.push(shipModule);
    }
  }

  public changeProbability(id: string, messageType: OptionalMessageTypes, probability: number) {
    const messageProbabilities = this._getShipModuleById(id, false)?.messageProbabilities;
    const message = messageProbabilities?.[messageType];
    if (typeof message === 'undefined') {
      return;
    }
    console.log(id, messageType, probability);
    // @ts-ignore
    messageProbabilities[messageType] = probability
  }

  public changeAllProbabilities(warningProbability: number, criticalProbability: number): void {
    this.changeAllProbabilitiesOfShipModules(this._getShipModules(false), warningProbability, criticalProbability);
  }

  public changeAllProbabilitiesOfShipModules(shipModules: ShipModule[], warningProbability: number, criticalProbability: number): void {
    shipModules.forEach((shipModule) => {
      this.changeProbability(shipModule.id, MessageType.WARNING, warningProbability);
      this.changeProbability(shipModule.id, MessageType.CRITICAL, criticalProbability);

      if (shipModule.subModules) {
        this.changeAllProbabilitiesOfShipModules(shipModule.subModules, warningProbability, criticalProbability);
      }
    })
  }

  private _getShipModules(clone = true): ShipModule[] {
    return clone ? cloneDeep(this.shipModules) : this.shipModules;
  }

  private _getShipModuleById(id: string, clone = true) {
    return this.findShipModuleRecursively(id, this._getShipModules(clone));
  }

  private findShipModuleRecursively(id: string, modulesToSearch: ShipModule[]): ShipModule | undefined {
    for (const shipModule of modulesToSearch) {
      if (shipModule.id === id) {
        return shipModule;
      }

      if (shipModule.subModules?.length) {
        const foundShipModule = this.findShipModuleRecursively(id, shipModule.subModules);
        if (foundShipModule) {
          return foundShipModule;
        }
      }
    }
    return undefined;
  }

}

export const getProbability = (messageType: OptionalMessageTypes, probabilities?: MessageProbabilities): number => {
  if (!probabilities) {
    return 0;
  }

  return probabilities[messageType] ?? 0;
}


const shipModuleService = new ShipModuleService(cloneDeep(ShipModuleData));

export default shipModuleService;
