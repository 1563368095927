import './AnalyzeScreenPage.scss';
import React from 'react';
import shipService, {Ship} from '../services/ship-service';
import PropTypes from 'prop-types';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';
import {Button, FrameUnderline, Text} from '@arwes/core';
import shipModuleService, {
  getProbability,
  MessageProbabilities,
  MessageSet,
  MessageType, OptionalMessageTypes,
  ShipModule
} from '../services/ship-module-service';
import {audioSettings, bleepsSettings, generalAnimator, playersSettings} from '../config';
import {BleepsProvider} from '@arwes/sounds';
import {Animator, AnimatorGeneralProvider} from '@arwes/animation';

interface RoutingParamsType {
  id: string;
}

interface ComponentParams extends RouteComponentProps<RoutingParamsType> {
}

interface State {
  ship: Ship;
  activeModules: ShipModule[];
}

const defaultAnimator = {duration: {enter: 200, exit: 200, stagger: 100}};

class AnalyzeScreenPage extends React.Component<ComponentParams, State> {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  public constructor(params: ComponentParams) {
    super(params);
  }

  public componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ship: shipService.getShipById(id) as Ship, activeModules: shipModuleService.getActiveShipModules()});
  }

  public render() {
    const ship = this.state?.ship;
    if (!ship) {
      return <div>No ship loaded</div>;
    }
    return <div className="analyzescreen-page">
      <BleepsProvider
        audioSettings={audioSettings}
        playersSettings={playersSettings}
        bleepsSettings={bleepsSettings}
      >
        <AnimatorGeneralProvider animator={generalAnimator}>
          <Animator animator={{manager: 'sequence', activate: true}}>
            <Link to={`/ship/${ship.id}`} >
              <Button className="back-button" FrameComponent={FrameUnderline}>
                <Text>← Zur Übersicht</Text>
              </Button>
            </Link>
            <Text as="h1">
              {ship.name}
            </Text>
          </Animator>
        </AnimatorGeneralProvider>
        <AnimatorGeneralProvider animator={defaultAnimator}>
          <Animator animator={{manager: 'sequence', activate: true}}>
            {this.renderAnalyzeList()}
          </Animator>
        </AnimatorGeneralProvider>
      </BleepsProvider>
    </div>;
  }


  private renderAnalyzeList() {
    return this.renderAnalyzeListRecursively(shipModuleService.getActiveShipModules());
  }

  private renderAnalyzeListRecursively(shipModules: ShipModule[], depth = 0) {
    return <ul>
      {shipModules.map((shipModule, i) => {
          const messageSet = this.getModuleAnalyzeOutput(shipModule);
          return <li key={shipModule.id}>
            {messageSet?.intro ? <><Text>{messageSet.intro}</Text></> : ''}
            {messageSet?.ok ? <><br /><Text>{messageSet.ok}</Text></> : ''}
            {messageSet?.info ? <><br /><Text>{messageSet.info}</Text></> : ''}
            {messageSet?.critical ? <><br /><Text className="critical">{messageSet.critical}</Text></> : ''}
            {messageSet?.warning ? <><br /><Text className="warning">{messageSet.warning}</Text></> : ''}
            {shipModule.subModules ? <>{this.renderAnalyzeListRecursively(shipModule.subModules, 1)}</> : ""}
          </li>;
        }
      )}
    </ul>;
  }

  private getModuleAnalyzeOutput(shipModule: ShipModule): MessageSet | undefined {
    const criticalProbability: number = getProbability(MessageType.CRITICAL, shipModule.messageProbabilities);
    const warningProbability: number = getProbability(MessageType.WARNING, shipModule.messageProbabilities);

    const criticalOccured = criticalProbability !== 0 && Math.random() <= criticalProbability;
    const warningOccured = warningProbability !== 0 && Math.random() <= warningProbability;

    if(!shipModule.messages) {
       return undefined;
    }

    return {
      [MessageType.INTRO]: shipModule.messages?.intro,
      [MessageType.OK]: !criticalOccured && !warningOccured ? shipModule.messages?.ok : undefined,
      [MessageType.CRITICAL]: criticalOccured ? shipModule.messages?.critical : undefined,
      [MessageType.WARNING]: warningOccured ? shipModule.messages?.warning : undefined,
    };
  }

}

export default withRouter<ComponentParams, any>(AnalyzeScreenPage);
